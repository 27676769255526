<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="12">
          <el-form-item label="文件类型">
            <el-radio-group v-model="fileType" size="small">
              <el-radio-button label="image">图片</el-radio-button>
              <el-radio-button label="video">视频</el-radio-button>
              <el-radio-button label="audio">音频</el-radio-button>
              <el-radio-button label="doc">文件</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="文件名">
            <el-input v-model="queryFileName" placeholder="TODO"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="onQuery">查询</el-button>
          <el-button @click="onQuery">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="cosList" highlight-current-row style="width: 100%">
      <el-table-column prop="fileName" label="名称" width="200"> </el-table-column>
      <el-table-column prop="cosKey" label="cosKey" width="200"> </el-table-column>
      <el-table-column prop="createTime" :formatter="formatTime" label="创建时间"> </el-table-column>
      <el-table-column label="预览" width="100">
        <template slot-scope="scope">
          <span class="cosFileName" v-if="scope.row.cloudService == 0">
            <a :href="scope.row.url" target="_blank">{{ scope.row.fileName }}</a>
          </span>
          <span v-else>
            <!-- <span @click="gotoVodView(scope.row)">{{ scope.row.fileName }}</span> -->
            <el-button size="small" @click="gotoVodView(scope.row)">预览视频</el-button>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="onSelect(scope.$index, scope.row)">选择</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      >
      </el-pagination>
    </el-col>
  </section>
</template>

<script>
import { cosApi } from "@/api/cosApi";
import { epochToDateString } from "@/util/TimeUtil";

export default {
  data() {
    return {
      queryFileName: "",
      fileType: "image",
      cosList: [],
      currentPage: 1,
      totalPage: 10,
    };
  },
  mounted() {
    this.onQuery();
  },
  methods: {
    formatTime: function(row, column) {
      return epochToDateString(row[column.property]);
    },
    onChangePage(val) {
      this.currentPage = val;
      this.onQuery();
    },
    onQuery() {
      let start = (this.currentPage - 1) * 20;
      let len = 20;
      if (this.queryFileName) {
        cosApi
          .searchCos(this.fileType, this.queryFileName, start, len)
          .then((ret) => {
            let { code, data, totalPage } = ret;
            if (code == 0) {
              this.totalPage = totalPage;
              this.cosList = data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        cosApi
          .pagerCos(this.fileType, start, len)
          .then((ret) => {
            let { code, data, totalPage } = ret;
            if (code == 0) {
              this.totalPage = totalPage;
              this.cosList = data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    gotoVodView(row) {
      const routeData = this.$router.resolve({ name: "doge-view", query: { vcode: row.vcode } });
      window.open(routeData.href, "_blank");
    },
    //删除
    onSelect(index, row) {
      this.$emit("get-select", row);
    },
  },
  watch: {
    fileType: function(newVal, oldVal) {
      console.log(newVal, oldVal);
      this.currentPage = 1;
      this.onQuery();
    },
    queryFileName: function(newVal, oldVal) {
      this.currentPage = 1;
      //console.log("queryFileName", newVal);
    },
  },
};
</script>

<style scoped></style>
