<template>
  <div id="markdown">
    <div v-for="(item, index) in markdownList" style="margin-top: 10px" :key="index">
      <div v-if="item.unitType === markdown_type" @change="markdownDivChange(item.unitId, index)">
        <mavon-editor
          :tabSize="tabsize"
          @change="markdownChange"
          v-model="item.markDown.content"
          :value="item.markDown.content"
          :toolbars="toolbars"
          style="margin-top: 10px; min-height: 200px; height: 200px"
        ></mavon-editor>
      </div>
      <el-row v-if="item.unitType === cos_type">
        <el-col :span="8">
          <el-input v-model="item.cos.showTitle" class="cosBorder"></el-input>
        </el-col>
        <el-col :span="14" :offset="2">
          <div class="cosBorder">
            <span class="cosFileName" v-if="item.cos.cloudService == 0">
              <a :href="item.cos.url" target="_blank">{{ item.cos.fileName }}</a>
            </span>
            <span v-else>
              <el-button size="small" @click="gotoVodView(item.cos)">{{ item.cos.fileName }}</el-button>
            </span>
          </div>
        </el-col>
      </el-row>

      <div v-if="item.unitType === html_type">
        <el-input type="textarea" v-model="item.html.content" :rows="8"></el-input>
      </div>

      <el-row v-if="item.unitType === vod_type">
        <el-col :span="8">
          <el-input v-model="item.vod.showTitle"></el-input>
        </el-col>
        <el-col :span="8" :offset="1">
          <el-input v-model="item.vod.fileId" :disabled="true"></el-input>
        </el-col>
        <el-col :span="4" :offset="1">
          <el-button size="small" @click="onViewVod(item.vod.fileId)">预览</el-button>
        </el-col>
      </el-row>

      <div v-if="item.unitType === practice_type">
        <el-row>
          <el-col :span="3">
            <span v-if="item.practice.practiceType === choice_type">
              <span style="margin-left: 8px">选择题</span>
            </span>
            <span v-if="item.practice.practiceType === fillBlank_type">
              <span style="margin-left: 8px">填空题</span>
            </span>
            <span v-if="item.practice.practiceType === codeFollow_type">
              <span style="margin-left: 8px">跟练</span>
            </span>
            <span v-if="item.practice.practiceType === codePractice_type">
              <span style="margin-left: 8px">代码练习</span>
            </span>
            <span v-if="item.practice.practiceType === ''">
              <span style="margin-left: 8px">绑定练习</span>
            </span>
          </el-col>
          <el-col :span="8">
            <el-input size="small" v-model="item.practice.showTitle"></el-input>
          </el-col>
          <el-col :span="9" :offset="1">
            <el-input
              style="width: 250px"
              size="small"
              v-model="item.practice.practiceName"
              :disabled="true"
            ></el-input>
          </el-col>
        </el-row>
      </div>

      <el-row style="margin-top: 10px">
        <el-button v-if="openMd" @click="insertMarkdown(index)" size="mini">插入段落</el-button>
        <el-button v-if="openHtml" @click="insertHtml(index)" size="mini">插入html</el-button>
        <el-button v-if="openCos" size="mini" @click="insertMedia(index)">插入cos</el-button>
        <el-button v-if="openVod" @click="insertVod(index)" size="mini">插入vod</el-button>
        <!-- <el-button
          v-if="openPractice"
          @click="insertPractice(index)"
          size="mini"
          >插入练习</el-button
        > -->
        <el-button @click="markdownMoveUp(index)" size="mini">上移</el-button>
        <el-button @click="markdownMoveDown(index)" size="mini">下移</el-button>
        <el-button @click="deleteMarkdown(item.unitId, index)" size="mini">删除</el-button>
      </el-row>
      <el-dialog title="提示" :visible.sync="dialogVisible" width="80%" height="80%">
        <aidaCosSelector v-model="cosRet" v-on:get-select="onGetSelectCos"></aidaCosSelector>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="预览" :visible.sync="dialogShowFileVisible">
        <div slot="footer" class="dialog-footer">
          <div class="showFileCover" v-if="showFileType === 'png' || showFileType === 'jpg' || showFileType === 'jpeg'">
            <img class="showFile" :src="showFileUrl" alt />
          </div>
          <div class="showFileCover" v-if="showFileType === 'mp4' || showFileType === 'avi' || showFileType === 'rmvb'">
            <video class="showFile" controls :src="showFileUrl">
              你的浏览器不支持
              <code>video</code> 标签.
            </video>
          </div>
          <el-button @click="dialogShowFileVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-dialog title="选择VOD" :visible.sync="dlgShowVod" width="90%" height="90%">
        <vodSelector v-on:get-select="onGetSelectVod"></vodSelector>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dlgShowVod = false">取 消</el-button>
        </span>
      </el-dialog>

      <el-dialog title="选择练习内容" :visible.sync="dlgShowPractice" width="90%" height="90%">
        <!-- <PracticeSelector
          v-on:select-practice="onGetSelectPractice"
        ></PracticeSelector> -->
        <span slot="footer" class="dialog-footer">
          <el-button @click="dlgShowPractice = false">取 消</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mavonEditor } from "mavon-editor";
import "mavon-editor/dist/css/index.css";
import aidaCosSelector from "@/component/aidaCosSelector.vue";
import { setTimeout } from "timers";
import { MarkdownFileType, PracticeTypeEnum, UnitType } from "@/util/EnumUtil";
import { alertMessage } from "@/util/alertMessageUtil.js";
//import PracticeSelector from "@/views/component/PracticeSelector";
import vodSelector from "@/component/vodSelector.vue";

export default {
  data() {
    return {
      markdownValue: "",
      htmlValue: "",
      toolbars: {
        link: true, // 链接
        imagelink: true, // 图片链接
        table: true, // 表格
        undo: true, // 上一步
        redo: true, // 下一步
        save: true, // 保存（触发events中的save事件）
        preview: true, // 预览
      },
      tabsize: 4,
      dialogVisible: false,
      cosRet: {},
      currentIndex: 0,

      markdown_type: UnitType.MARKDOWN,
      cos_type: UnitType.COS,
      html_type: UnitType.HTML,
      vod_type: UnitType.VOD,
      practice_type: UnitType.PRACTICE,

      choice_type: PracticeTypeEnum.CHOICE,
      fillBlank_type: PracticeTypeEnum.FILL_BLANK,
      codeFollow_type: PracticeTypeEnum.CODE_FOLLOW,
      codePractice_type: PracticeTypeEnum.CODE_PRACTICE,

      dialogShowFileVisible: false,
      dlgShowVod: false,
      dlgShowPractice: false,
      showFileUrl: "",
      showFileType: "",
    };
  },
  model: {
    prop: "markdownList",
    event: "change",
  },
  props: {
    markdownList: Array,
    openMd: Boolean,
    openHtml: Boolean,
    openCos: Boolean,
    openVod: Boolean,
    openPractice: Boolean,
  },

  components: {
    mavonEditor,
    aidaCosSelector,
    //PracticeSelector,
    vodSelector,
  },

  methods: {
    insertMarkdown(index) {
      let i = this.markdownList.length - 1;
      let obj = {
        unitType: MarkdownFileType.MARKDOWN,
        unitId: this.markdownList[i].unitId + 1,
        index: this.markdownList[i].index + 1,
        content: "",
        fileName: "",
        url: "",
        markDown: {
          content: "",
        },
        // html: {
        //   content: ""
        // },
        // cos: {
        //   cosId: "",
        //   cosKey: "",
        //   fileName: "",
        //   fileType: ""
        // },
        // vod: {
        //   fileId: "",
        //   fileName: ""
        // },
        // practice: {
        //   practiceId: "",
        //   practiceType: "",
        //   practiceName: ""
        // }
      };
      this.markdownList.splice(index + 1, 0, obj);
    },
    insertHtml(index) {
      let i = this.markdownList.length - 1;
      let obj = {
        unitType: MarkdownFileType.HTML,
        unitId: this.markdownList[i].unitId + 1,
        index: this.markdownList[i].index + 1,
        content: "",
        fileName: "",
        url: "",
        html: {
          content: "",
        },
      };
      this.markdownList.splice(index + 1, 0, obj);
    },
    insertMedia(index) {
      this.currentIndex = index;
      this.dialogVisible = true;
    },
    insertVod(index) {
      this.dlgShowVod = true;
      this.currentIndex = index;
    },
    insertPractice(index) {
      this.dlgShowPractice = true;
      this.currentIndex = index;
    },
    deleteMarkdown(e, index) {
      if (this.markdownList.length === 1) {
        alertMessage("不能再删了", "，warning");
        return;
      }
      this.markdownList.splice(index, 1);
    },
    markdownMoveUp(index) {
      if (index >= 1) {
        setTimeout(() => {
          let temp = this.markdownList[index - 1];
          this.$set(this.markdownList, index - 1, this.markdownList[index]);
          this.$set(this.markdownList, index, temp);
        }, 200);
      } else {
        alertMessage("到顶了", "，warning");
      }
    },
    markdownMoveDown(index) {
      if (index < this.markdownList.length - 1) {
        setTimeout(() => {
          let temp = this.markdownList[index + 1];
          this.$set(this.markdownList, index + 1, this.markdownList[index]);
          this.$set(this.markdownList, index, temp);
        }, 200);
      } else {
        alertMessage("到底了", "warning");
      }
    },
    markdownDivChange(id) {
      console.log("markdownDivChange..");
      this.markdownList.map((item) => {
        if (item.unitId === id) {
          item.content = this.markdownValue;
        }
        return item;
      });
    },
    markdownChange(value, render) {
      this.markdownValue = value;
      this.htmlValue = render;
    },
    showFileDetail(type, url) {
      this.showFileUrl = url;
      this.showFileType = type;
      this.dialogShowFileVisible = true;
    },
    onGetSelectCos(val) {
      let i = this.markdownList.length - 1;
      console.log("get cos...", val);
      let obj = {
        unitType: MarkdownFileType.COS,
        unitId: this.markdownList[i].unitId + 1,
        index: this.markdownList[i].index + 1,
        fileName: val.fileName,
        fileType: val.fileType,
        content: val.cosKey,
        url: val.url,
        cos: {
          cosId: val.cosId,
          cosKey: val.cosKey,
          fileName: val.fileName,
          fileType: val.fileType,
          url: val.url,
          showTitle: val.fileName,
          cloudService: val.cloudService,
          vid: val.vid,
          vcode: val.vcode,
        },
      };
      this.markdownList.splice(this.currentIndex + 1, 0, obj);
      this.dialogVisible = false;
    },
    gotoVodView(row) {
      const routeData = this.$router.resolve({ name: "doge-view", query: { vcode: row.vcode } });
      window.open(routeData.href, "_blank");
    },
    onGetSelectVod(val) {
      console.log(val);
      let i = this.markdownList.length - 1;
      let obj = {
        unitType: MarkdownFileType.VOD,
        unitId: this.markdownList[i].unitId + 1,
        index: this.markdownList[i].index + 1,
        fileName: val.fileName,
        fileType: "vod",
        content: val.fileId,
        url: "",
        vod: {
          fileId: val.fileId,
          fileName: val.fileName,
          showTitle: val.fileName,
        },
      };
      this.markdownList.splice(this.currentIndex + 1, 0, obj);
      this.dlgShowVod = false;
    },
    onViewVod(fileId) {
      let path = "/vod/view/" + fileId;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    // onGetSelectPractice(val) {
    //   console.log(val);
    //   let i = this.markdownList.length - 1;
    //   let obj = {
    //     unitType: MarkdownFileType.PRACTICE,
    //     unitId: this.markdownList[i].unitId + 1,
    //     index: this.markdownList[i].index + 1,
    //     fileName: val.practiceName,
    //     fileType: val.practiceType,
    //     content: val.practiceId,
    //     url: "",
    //     practice: {
    //       practiceId: val.practiceId,
    //       practiceType: val.practiceType,
    //       practiceName: val.practiceName,
    //       showTitle: val.practiceName,
    //     },
    //   };
    //   this.markdownList.splice(this.currentIndex + 1, 0, obj);
    //   this.dlgShowPractice = false;
    // },
  },
};
</script>

<style>
#markdown {
  margin: auto;
  width: 100%;
  padding-bottom: 10px;
}

.cosBorder {
  border: 1px solid #dcdfe6;
  margin: 10px 0px;
}
.showFileCover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}
.showFileCover :hover {
  cursor: pointer;
}
.showFile {
  width: 320px;
  height: 320px;
}
</style>
