<template>
  <section>
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form label-width="80px">
        <el-col :span="8">
          <el-form-item label="文件名">
            <el-input v-model="queryText" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="query">查询</el-button>
          <el-button @click="query">刷新</el-button>
        </el-col>
      </el-form>
    </el-col>

    <!--列表-->
    <el-table :data="vodList" highlight-current-row style="width: 100%">
      <el-table-column
        prop="fileName"
        label="名称"
        width="200"
      ></el-table-column>
      <el-table-column
        prop="fileId"
        label="fileId"
        width="300"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="updateTime"
        width="200"
      ></el-table-column>
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="onSelect(scope.$index, scope.row)"
            >选择</el-button
          >
          <el-button size="small" @click="onViewVod(scope.$index, scope.row)"
            >预览</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-count="totalPage"
        @current-change="onChangePage"
      ></el-pagination>
    </el-col>
  </section>
</template>

<script>
import { vodApi } from "@/api/vodApi";

export default {
  data() {
    return {
      vodList: [],
      currentPage: 1,
      totalPage: 1,
      queryText: "",
    };
  },
  mounted() {
    this.query();
  },
  methods: {
    search() {
      vodApi
        .searchVod(this.queryText)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.totalPage = totalPage;
            this.vodList = data;
          }
        })
        .catch((err) => {
          this.$message({
            message: "搜索失败" + err,
            type: "error",
          });
        });
    },
    query() {
      if (this.queryText) {
        this.search();
        return;
      }
      vodApi
        .listVod(this.currentPage)
        .then((ret) => {
          let { code, data, totalPage } = ret;
          if (code == 0) {
            this.totalPage = totalPage;
            this.vodList = data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChangePage(val) {
      this.currentPage = val;
      console.log(`query 第${val}页`);
      this.query();
    },
    onViewVod(index, row) {
      let path = "/vod/view/" + row.fileId;
      let routeData = this.$router.resolve({ path: path });
      window.open(routeData.href, "_blank");
    },
    onSelect(index, row) {
      this.$emit("get-select", row);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped></style>
