import ajax from "./ajax.js";
import Qs from "qs";

let base = process.env.VUE_APP_BASE_URL;

const addCos = (cos) => {
  return ajax.post(`${base}/api/itedu/v1/cos`, cos);
};

///api/itedu/v1/cos/get
const getCos = (cosId) => {
  return ajax.get(`${base}/api/itedu/v1/cos/get?cosId=${cosId}`);
};

const pagerCos = (fileType, start, len) => {
  return ajax.get(`${base}/api/itedu/v1/cos/pager?fileType=${fileType}&start=${start}&len=${len}`);
};

const searchCos = (fileType, fileName, start, len) => {
  return ajax.get(
    `${base}/api/itedu/v1/cos/search?fileType=${fileType}&fileName=${fileName}&start=${start}&len=${len}`
  );
};

const deleteCosByAuth = (cosId) => {
  return ajax.post(`${base}/api/itedu/v1/cos/delete-by-auth?cosId=${cosId}`);
};

const deleteCosByYou = (cosId) => {
  return ajax.post(`${base}/api/itedu/v1/cos/delete-by-you?cosId=${cosId}`);
};

const getBucketRegion = (type) => {
  return ajax.get(`${base}/api/itedu/v1/cos/bucket-region?type=${type}`);
};

const getCosHolder = (cosDirParam, fileName) => {
  return ajax.get(`${base}/api/itedu/v1/cos/holder?cos=${cosDirParam}&fileName=${fileName}`);
};

const COS_STS_URL = `${base}/api/itedu/v1/cos/appid`;

const COS_STS_URL_PUB = `${base}/api/itedu/v1/pub/appid`;

const COS_STS_URL_INNER_SHARE_BOX = `${base}/api/itedu/v1/inner-share-box/appid`;

const getPubHolder = (fileName) => {
  return ajax.get(`${base}/api/itedu/v1/cos/pub-holder?fileName=${fileName}`);
};

const addPub = (cos) => {
  return ajax.post(`${base}/api/itedu/v1/pub`, cos);
};
// 用户网盘相关
// 有用
const addUserCos = (userCos) => {
  return ajax.post(`${base}/api/itedu/v1/user-cos/add`, userCos);
};

const updateUserCos = (id, title, courseList) => {
  return ajax.post(`${base}/api/itedu/v1/user-cos/update?id=${id}&title=${title}&courseList=${courseList}`);
};

const createPdfPreview = (id) => {
  return ajax.post(`${base}/api/itedu/v1/user-cos/create-pdf-preview?id=${id}`);
};

const deleteUserCos = (id) => {
  return ajax.post(`${base}/api/itedu/v1/user-cos/delete?id=${id}`);
};

// 有用
const getUserCosHolder = () => {
  return ajax.get(`${base}/api/itedu/v1/user-cos/holder`);
};

// 文件夹标题是否重复
const isTitleExist = (title) => {
  return ajax.get(`${base}/api/itedu/v1/user-cos/title/exist?title=${title}`);
};

const userCosPager = (start, len) => {
  return ajax.get(`${base}/api/itedu/v1/user-cos/pager?start=${start}&len=${len}`);
};

const userCosSearch = (title, start, len) => {
  return ajax.get(`${base}/api/itedu/v1/user-cos/search?title=${title}&start=${start}&len=${len}`);
};

const userCosDownloadUrl = (id) => {
  return ajax.get(`${base}/api/itedu/v1/user-cos/download-url?id=${id}`);
};

// 内网中转站

const getInnerShareBoxBucketRegion = () => {
  return ajax.get(`${base}/api/itedu/v1/inner-share-box/bucket-region`);
};

const queryInnerShareBox = (start, len) => {
  return ajax.get(`${base}/api/itedu/v1/inner-share-box/query`);
};

const getTmpCosObject = (bizType, fileName) => {
  return ajax.get(`${base}/api/itedu/v1/cos/tmp-cos-object?bizType=${bizType}&fileName=${fileName}`);
};

const getCdnUrl = (key) => {
  ///api/itedu/v1/cos/tmp-cos-object
  return ajax.get(`${base}/api/itedu/v1/cos/cdn-url?key=${key}`);
};

const getDogeCloudTmpToken = (bizType, fileName) => {
  //  ///api/itedu/v1/doge-cloud/tmp-token
  return ajax.get(`${base}/api/itedu/v1/doge-cloud/tmp-token?bizType=${bizType}&fileName=${fileName}`);
};

const getDogeCloudVodUploadDone = (did) => {
  ///api/itedu/v1/doge-cloud/vod-upload-done
  return ajax.get(`${base}/api/itedu/v1/doge-cloud/vod-upload-done?did=${did}`);
};

const setCosTmpFinish = (id) => {
  ///api/itedu/v1/doge-cloud/vod-upload-done
  return ajax.post(`${base}/api/itedu/v1/cos/tmp-cos-set-finish?id=${id}`);
};

//api/itedu/v1/cos/doge-tmp-cos-set-finish
const setDogeCosTmpFinish = (id, vid, vcode, did) => {
  ///api/itedu/v1/doge-cloud/vod-upload-done
  return ajax.post(`${base}/api/itedu/v1/cos/doge-tmp-cos-set-finish?id=${id}&vid=${vid}&vcode=${vcode}&did=${did}`);
};

const batchAddCos = (cosObjectList) => {
  ///api/itedu/v1/doge-cloud/vod-upload-done
  return ajax.post(`${base}/api/itedu/v1/cos/batch-add`, cosObjectList);
};

// const deleteCosTmp = (cosTmp) => {
//   ///api/itedu/v1/cos/tmp-cos-object-delete
//   return ajax.post(`${base}/api/itedu/v1/cos/tmp-cos-object-delete`, cosTmp);
// };

const awaitChain = (promise) => {
  return promise.then((data) => [data, null]).catch((err) => [null, err]);
};

export const cosApi = {
  addCos: addCos,
  getCos: getCos,
  pagerCos: pagerCos,
  searchCos: searchCos,
  deleteCosByAuth: deleteCosByAuth,
  deleteCosByYou: deleteCosByYou,
  getBucketRegion: getBucketRegion,
  getCosHolder: getCosHolder,
  COS_STS_URL: COS_STS_URL,
  COS_STS_URL_PUB: COS_STS_URL_PUB,
  getPubHolder: getPubHolder,
  addPub: addPub,
  // 用户网盘相关
  getUserCosHolder: getUserCosHolder,
  addUserCos: addUserCos,
  updateUserCos: updateUserCos,
  createPdfPreview: createPdfPreview,
  deleteUserCos: deleteUserCos,
  isTitleExist: isTitleExist,
  userCosPager: userCosPager,
  userCosSearch: userCosSearch,
  userCosDownloadUrl: userCosDownloadUrl,
  // 内网中转站相关
  getInnerShareBoxBucketRegion: getInnerShareBoxBucketRegion,
  COS_STS_URL_INNER_SHARE_BOX: COS_STS_URL_INNER_SHARE_BOX,
  queryInnerShareBox: queryInnerShareBox,
  // 从cos tmp 转到 cos object的上传方式
  getTmpCosObject: getTmpCosObject,
  getCdnUrl: getCdnUrl,
  getDogeCloudTmpToken: getDogeCloudTmpToken,
  getDogeCloudVodUploadDone: getDogeCloudVodUploadDone,
  setCosTmpFinish: setCosTmpFinish,
  setDogeCosTmpFinish: setDogeCosTmpFinish,
  batchAddCos: batchAddCos,
  awaitChain: awaitChain,
};
