import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const APP_ID = "1251206464";

const addVod = vod => {
  return ajax.post(`${base}/api/itedu/v1/vod`, vod);
};

const getVod = fileId => {
  return ajax.get(`${base}/api/itedu/v1/vod/get?fileId=${fileId}`);
};

const listVod = pageNo => {
  return ajax.get(`${base}/api/itedu/v1/vod/query?pageNo=${pageNo}`);
};

const deleteVod = fileId => {
  return ajax.post(`${base}/api/itedu/v1/vod/delete?fileId=${fileId}`);
};

const searchVod = text => {
  return ajax.get(`${base}/api/itedu/v1/vod/query/search?text=${text}`);
};

const getVodParam = fileId => {
  return ajax.get(`${base}/api/itedu/v1/teach/vodparam?fileId=${fileId}`);
};

const getVodUrl = fileId => {
  return ajax.get(`${base}/api/itedu/v1/teach/vodurl?fileId=${fileId}`);
};

export const vodApi = {
  APP_ID: APP_ID,
  addVod: addVod,
  listVod: listVod,
  deleteVod: deleteVod,
  searchVod: searchVod,
  getVodParam: getVodParam,
  getVodUrl: getVodUrl,
  getVod: getVod,
};
